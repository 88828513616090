import React from "react";
import { CaseStudyData, SoftwareServiceDescription } from "./WebDevelopmentData";
import { NativeDevelopmentIcon } from "../../components/layout/icons/NativeDevelopmentIcon";

export const nativeDevelopmentData: NativeDevPageData = {
    metaPageTitle: "Native Development & Free Play Case Study - iDream Interactive",
    metaKeywords: "react native app development, native app development, native mobile app development, native development, native ios development",
    metaDescription: "As mobile phones become more popular, the need for feature rich mobile applications has increased.  Find out how we tackle the latest mobile technologies.",
    title: "Native Development",
    subtitle: "As mobile phones become more popular, the need for feature rich mobile applications has increased.",
    icon: <NativeDevelopmentIcon fill={"#ffffff"} />,
    description:
    {
        title: "Gain access to the latest and greatest technology specific to your platform.",
        description: <>
            <p>Publishing to both iOS and Android using native development requires two different workflows using two different code bases.  This doesn’t always mean twice the work but it does increase the development lifecycle.  The advantage of using the native development platform is that your users have a better, more familiar experience.</p>
            <p>Let’s connect to better understand if a native development solution is right for you.</p>
        </>,
    },
    caseStudy: {
        deliverables: ["Native Development", "SaaS Development", "UI/UX Design", "Front-end Development", "Back-end Development"],
        client: "Twin Spires",
        title: "Free Play",
        description: <>
            <p>
                After great success with the Twin Spires Edge blog site, the Churchill Downs TwinSpires team requested
                our help with creating a native FreePlay app.
            </p>
            <p>
                They were looking for someone to create a seamless app experience for both iOS and Android.
            </p>
            <p>
                We worked directly with their team to ensure the app was on brand and included all the functionality they required.
            </p>
        </>,
    }
}

type NativeDevPageData = {
    metaPageTitle: string,
    metaKeywords: string,
    metaDescription: string,
    title: string,
    subtitle: string,
    icon: JSX.Element,
    description: SoftwareServiceDescription,
    caseStudy: CaseStudyData,
}