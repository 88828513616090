import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/Layout";
import { Navigation } from "../../components/navigation/Navigation";
import { HeaderSize, ParallaxHeader } from "../../components/layout/ParallaxHeader";
import { SoftwareHeaderInfo } from "../../components/layout/SoftwareHeaderInfo";
import { Container } from "../../components/layout/Container";
import { StraightContainer } from "../../components/layout/StraightContainer";
import { LeftContainer } from "../../components/layout/LeftContainer";
import { ContainerColour, ContainerSize } from "../../components/layout/DiagonalContainer";
import { CaseStudy } from "../../components/sections/softwareDevelopment/CaseStudy";
import { Gallery } from "../../components/layout/Gallery";
import { nativeDevelopmentData } from "../../content/pageData/NativeDevelopmentData";
import { NativeDevelopmentIcon } from "../../components/layout/icons/NativeDevelopmentIcon";
import { Button, ButtonColour, ButtonSize } from "../../components/layout/Button";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        edgeMobile: {
            childImageSharp: any;
        }
        edge: {
            childImageSharp: any;
        }
        description: {
            childImageSharp: any;
        }
        gallery1: {
            childImageSharp: any;
        }
        gallery2: {
            childImageSharp: any;
        }
        gallery3: {
            childImageSharp: any;
        }
        gallery4: {
            childImageSharp: any;
        }
        gallery5: {
            childImageSharp: any;
        }
        gallery6: {
            childImageSharp: any;
        }
        allMarkdownRemark: {
            edges: [{
                node: {
                    fields: {
                        slug: string
                    }
                    frontmatter: {
                        title: string
                    }
                }
            }]
        }
    }
}

export default ({ data }: PageData) => {
    const edgeMobile = data.edgeMobile.childImageSharp.fluid;
    const edge = data.edge.childImageSharp.fluid;

    const edgeSources = [
        edgeMobile,
        {
            ...edge,
            media: `(min-width: 768px)`,
        }
    ]

    const images = [
        data.gallery1.childImageSharp.fluid,
        data.gallery2.childImageSharp.fluid,
        data.gallery3.childImageSharp.fluid,
        data.gallery4.childImageSharp.fluid,
        data.gallery5.childImageSharp.fluid,
        data.gallery6.childImageSharp.fluid,
    ]

    return (
        <Layout
            pageTitle={nativeDevelopmentData.metaPageTitle}
            keywords={nativeDevelopmentData.metaKeywords}
            description={nativeDevelopmentData.metaDescription}
            url={"software-development/native-development"}
        >
            <Navigation />
            <ParallaxHeader size={HeaderSize.LARGE} straightBottom={true} className="software-header">
                <SoftwareHeaderInfo
                    title={nativeDevelopmentData.title}
                    subtitle={nativeDevelopmentData.subtitle}
                    icon={<NativeDevelopmentIcon fill={"#ffffff"} />}
                    image={edgeSources}
                    imageAlt={"Three Free Play Screens"}
                    className="centered-header native-header"
                />
            </ParallaxHeader>
            <StraightContainer
                colour={ContainerColour.ORANGE}
                size={ContainerSize.SMALL}
                className="software-service-description native-service-description"
            >
                <LeftContainer
                    isSoftware={true}
                    title={nativeDevelopmentData.description.title}
                    description={nativeDevelopmentData.description.description}
                    image={data.description.childImageSharp.fluid}
                    imageAlt={"Free Play Leaderboards"}
                    className="software-sub-service"
                />
            </StraightContainer>
            <Container className="software-container">
                <CaseStudy
                    caseStudy={nativeDevelopmentData.caseStudy}
                />
            </Container>
            <Gallery isNative={true} images={images} />
            <Container className="software-container centered-button">
                <Button
                    buttonConfig={{
                        colour: ButtonColour.BLUE,
                        size: ButtonSize.LARGE,
                        ctaLabel: "Back",
                        ctaLink: "/software-development",
                        id: "BackGradient",
                        ariaLabel: "Return to Software Development",
                    }}
                />
            </Container>
        </Layout>
    );
};


export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        edgeMobile: file(relativePath: { eq: "assets/images/native-development/freeplay_mobile.png" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        edge: file(relativePath: { eq: "assets/images/native-development/freeplay.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        description: file(relativePath: { eq: "assets/images/native-development/description.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        gallery1: file(relativePath: { eq: "assets/images/native-development/gallery_1.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        gallery2: file(relativePath: { eq: "assets/images/native-development/gallery_2.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        gallery3: file(relativePath: { eq: "assets/images/native-development/gallery_3.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        gallery4: file(relativePath: { eq: "assets/images/native-development/gallery_4.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        gallery5: file(relativePath: { eq: "assets/images/native-development/gallery_5.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        gallery6: file(relativePath: { eq: "assets/images/native-development/gallery_6.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`